import React, { forwardRef } from 'react'
import PropTypes from 'prop-types';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import Content, { HTMLContent } from "../components/Content";
import FadeInSection  from "../components/FadeInSection";

const ThesisModule = forwardRef((props, forwardedRef) => {
  const contentComponent = HTMLContent;
  const PageContent = contentComponent || Content;

  return (
      <div className='thesis-freedoms-module thesis-nav-target is-align-items-center is-justify-content-center is-full one' ref={forwardedRef} id={props.id}>
          <div className="thesis-block">
            <div className="thesis-freedoms-topper is-flex is-justify-content-center is-align-items-center">
            {props.image ? (
                <div className="thesis-freedoms-illustration">
                  <FadeInSection>
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: props.image,
                        alt: `image for ${props.heading}`,
                      }}
                    />
                  </FadeInSection>
                </div>
              ) : null }
                <h4 className="thesis-freedoms-count">
                  <FadeInSection>
                    0{props.count}
                  </FadeInSection>
                </h4> 
            </div>
            <FadeInSection>
              <h3 className="thesis-freedoms-heading">{props.heading}</h3>
              <PageContent content={props.content}></PageContent>
            </FadeInSection>
          </div>
          {props.aside ? (
            <FadeInSection className="thesis-aside">
              <PageContent content={props.aside} className="thesis-aside-content"></PageContent>
            </FadeInSection>
          ) : null}
      </div>
  )
});

ThesisModule.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  featuredpost: PropTypes.object
}


export default ThesisModule;
