import React, { useState, useEffect, useRef } from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import Content, { HTMLContent } from "../components/Content";
import FreedomModule from "../components/FreedomModule";
import UpNext from "../components/UpNext";
import FadeInSection  from "../components/FadeInSection";
import SEO from "../components/SEO";

const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const update = () => {
      const { innerWidth } = window;
      setIsDesktop(innerWidth >= 1040);
    }

    update();
    window.addEventListener("resize", update);
    return () => {
      window.removeEventListener("resize", update);
    }
  }, []);

  return isDesktop;
}

// eslint-disable-next-line
export const ThesisPage = (props) => {
    const navItems = [
      { id: "intro", label: "Introduction", ref: useRef() },
      { id: "repetitive-work", label: "Freedom from repetitive work", ref: useRef() },
      { id: "transact", label: "Freedom to transact", ref: useRef() },
      { id: "disease", label: "Freedom from genetic disease", ref: useRef() },
      { id: "intersection", label: "Interconnection of humanity", ref: useRef() },
      { id: "conclusion", label: "Conclusion", ref: useRef() },
    ];

    const [isNavVisible, setIsNavVisible] = useState(false);
    const [inView, setInView] = useState([true, false, false, false, false]);
    const lastIndexInView = useRef(0);
    const navThresholdElementRef = useRef();

    const firstVisibleTabIndex = inView.indexOf(true) >= 0 ? inView.indexOf(true) : lastIndexInView.current;

    const isDesktop = useIsDesktop();

    useEffect(() => {
      if (!isDesktop) {
        return;
      }

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            setInView(prev => {
              const index = navItems.findIndex(item => item.id === entry.target.id);
              const newInView = [...prev];
              newInView[index] = entry.isIntersecting;

              if (entry.isIntersecting) {
                lastIndexInView.current = index;
              }

              return newInView;
            })
          });
        },
        { threshold: 0.05 }
      );

      navItems.forEach(item => {
        if (item.ref.current) {
          observer.observe(item.ref.current)
        }
      });

      return () => {
        navItems.forEach(item => {
          if (item.ref.current) {
            observer.unobserve(item.ref.current)
          }
        });
      };
    }, [isDesktop]);

    useEffect(() => {
      if (!isDesktop) {
        return;
      }

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            setIsNavVisible(!entry.isIntersecting);
          });
        }
      );

      if (navThresholdElementRef.current) {
        observer.observe(navThresholdElementRef.current)
      }

      return () => {
        if (navThresholdElementRef.current) {
          observer.unobserve(navThresholdElementRef.current)
        }
      };
    }, [isDesktop]);

    const frontmatter = props.data.markdownRemark.frontmatter;
    const title = frontmatter.title,
          introContentTop = frontmatter.introcontenttop,
          introContentBottom = frontmatter.introcontentbottom,
          freedoms = frontmatter.freedomslist,
          exitContentTop = frontmatter.exitcontenttop,
          exitContentTopMiddle = frontmatter.exitcontenttopmiddle,
          exitContentBottomMiddle = frontmatter.exitcontentbottommiddle,
          exitContentBottom = frontmatter.exitcontentbottom,
          contentComponent = HTMLContent,
          PageContent = contentComponent || Content;
    const upNext = props.data.upnext.nodes[0].frontmatter.upnextlinks.upnextthesis;

    return (
      <React.Fragment>
      <SEO {...props.data.seo} />
      <Helmet bodyAttributes={{ class: "page-thesis on-dark page-border" }}/>
      <section className="section section--gradient">
        <div className="radial-background"></div>
        <AnimatePresence>
          {isNavVisible && (
            <motion.nav
              aria-label="Page navigation"
              className="thesis-nav"
              transition={{ duration: 0.2 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <ol className="thesis-nav-list">
                {navItems.map((item, i) => (
                  <li key={item.id}>
                    <a
                      href={`#${item.id}`}
                      className="thesis-nav-item"
                      aria-current={firstVisibleTabIndex === i ? "page" : undefined}
                    >
                      <span className="thesis-nav-item-label">{item.label}</span>
                    </a>
                  </li>
                ))}
              </ol>
            </motion.nav>
          )}
        </AnimatePresence>
        <div className="thesis-container">
          <div className="thesis-nav-target" id={navItems[0].id} ref={navItems[0].ref}>
            <section className="page-title is-flex is-flex-direction-column is-justify-content-center is-align-items-center thesis-nav-target">
              <h6 className="has-text-centered" ref={navThresholdElementRef}>Our Thesis</h6>
              <h1 className="has-text-centered title">{title}</h1>
              <h3>{introContentTop.heading}</h3>
            </section>
            <section>
              <div className="intro-content is-multiline">
                <FadeInSection>
                  <div className="thesis-block">
                    <PageContent content={introContentTop.content}></PageContent>
                  </div>
                  { introContentTop.aside ? (
                  <div className="thesis-aside">
                    <PageContent content={introContentTop.aside} className="thesis-aside-content"></PageContent>
                  </div>
                ) : null }
                </FadeInSection>
                <div className="thesis-section">
                  <FadeInSection>
                    <div className="thesis-block bottom">
                      <h3>{introContentBottom.heading}</h3>
                      <PageContent content={introContentBottom.content}></PageContent>
                    </div>
                  </FadeInSection>
                </div>
              </div>
            </section>
          </div>
          <section>
            <div className="dark-background">
              <div className="thesis-freedoms is-multiline">
                  {freedoms.map(function(freedom, i){
                    let key = `freedom-${++i}`
                    return (
                      <FreedomModule
                        ref={navItems[i].ref}
                        id={navItems[i].id}
                        key={key}
                        count={i}
                        content={freedom.freedomcontent}
                        heading={freedom.freedomheading}
                        aside={freedom.freedomaside}
                        image={freedom.freedomimage}
                      />
                    )
                  })}
              </div>
            </div>
          </section>
          <section className="exit-content thesis-nav-target"  id={navItems[5].id} ref={navItems[5].ref}>
            <div className="thesis-section">
              <FadeInSection>
                <div className="thesis-block">
                  <h3>{exitContentTop.heading}</h3>
                  <PageContent content={exitContentTop.content}></PageContent>
                </div>
              </FadeInSection>
              { exitContentTop.aside ? (
                <FadeInSection className="thesis-aside">
                  <PageContent content={exitContentTop.aside} className="thesis-aside-content"></PageContent>
                </FadeInSection>
              ) : null }
            </div>
            <div className="thesis-section">
              <FadeInSection>
                <div className="thesis-block">
                  <h3>{exitContentTopMiddle.heading}</h3>
                  <PageContent content={exitContentTopMiddle.content}></PageContent>
                </div>
              </FadeInSection>
              { exitContentTopMiddle.aside ? (
                <FadeInSection className="thesis-aside">
                  <PageContent content={exitContentTopMiddle.aside} className="thesis-aside-content"></PageContent>
                </FadeInSection>
              ) : null }
            </div>
            <div className="thesis-section">
              <FadeInSection>
                <div className="thesis-block">
                  <h3>{exitContentBottomMiddle.heading}</h3>
                  <PageContent content={exitContentBottomMiddle.content}></PageContent>
                </div>
              </FadeInSection>
              <div className="thesis-aside">
                <div className="thesis-aside-content">
                  <p>Visit our <a href="/partners">partners page</a> for more information on how we leverage that network to support portfolio companies.</p>
                </div>
              </div>
            </div>
            <div className="thesis-section thesis-section-conclusion">
              <FadeInSection>
                <div className="thesis-block">
                  <h3>{exitContentBottom.heading}</h3>
                  <PageContent content={exitContentBottom.content}></PageContent>
                </div>
              </FadeInSection>
            </div>
          </section>
        </div>
        <UpNext url={upNext.linkurl} text={upNext.linktext}/>
    </section>
    </React.Fragment>
    );
};

ThesisPage.propTypes = {
  upnext: PropTypes.object
};

export default ThesisPage;


export const thesisPageQuery = graphql`
  query ThesisQuery($id: String!) {
    markdownRemark(id: {eq: $id}) {
      frontmatter {
        title
        introcontenttop {
          heading
          content
          aside
        }
        introcontentbottom {
          heading
          content
        }
        freedomslist {
          freedomheading
          freedomcontent
          freedomaside
          freedomimage{
            childImageSharp {
                gatsbyImageData(width: 756, height: 756)
              }
            }
        }
        exitcontenttop {
          heading
          content
          aside
        }
        exitcontenttopmiddle {
          heading
          content
          aside
        }
        exitcontentbottommiddle {
          heading
          content
        }
        exitcontentbottom {
          heading
          content
        }
      }
    }
    seo: markdownRemark(id: {eq: $id}) {
      ...SEOFragment
    }
    upnext: allMarkdownRemark(filter: {frontmatter: {filterKey: {eq: "globalsettings"}}}) {
      nodes {
        frontmatter {
          upnextlinks {
            upnextthesis {
              linktext
              linkurl
            }
          }
        }
      }
    }
  }
`;