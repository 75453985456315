import React, { useRef } from 'react';
import { useInView } from 'react-intersection-observer';

function FadeInSection(props) {

    const { ref, inView } = useInView();
    
    return (
      <div
        className={`fade-in-section ${inView ? 'is-visible' : ''} ${props.className ? props.className : ""}`}
        ref={ref}
      >
        {props.children}
      </div>
    );
  }

export default FadeInSection;